import React, { Component, useRef } from "react";

import { useLoader } from "@react-three/fiber";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
// import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import * as THREE from 'three';
import { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { Environment } from "@react-three/drei";
import { Html, useProgress, Stage, OrbitControls } from "@react-three/drei";
// import { useGLTF } from '@react-three/drei'

function Loader() {
  const { progress } = useProgress();
  return <Html center>{progress} % loaded</Html>;
}

function Model(props) {
  const groupRef = useRef();

  // const { nodes, materials } = useGLTF(props.path)
  // return (
  //   <group ref={groupRef} {...props} dispose={null}>
  //     <mesh castShadow receiveShadow geometry={nodes.Curve007_1.geometry} material={materials['Material.001']} />
  //     <mesh castShadow receiveShadow geometry={nodes.Curve007_2.geometry} material={materials['Material.002']} />
  //   </group>
  // )

  // const fbx = useLoader(FBXLoader, props.path)
  // return <primitive ref={groupRef} object={fbx} />

  const obj = useLoader(OBJLoader, props.path);
  return (
    <>
      <primitive
        // material={new THREE.MeshPhongMaterial({color: "#f00", shininess: 0, specular: "#040"})}
        material={new THREE.MeshPhongMaterial()}
        rotation={[-3.1415/2.0,0,0]}
        ref={groupRef}
        object={obj}
      />
    </>
  );
}

class Part extends Component {
  render() {
    const { node } = this.props;

    return (
      <>
          <Canvas>
            <Suspense fallback={<Loader />}>
              <OrbitControls autoRotate={true} autoRotateSpeed={5.0} />

            <Stage adjustCamera intensity={0.5} shadows="contact" environment="city">
              <hemisphereLight color="#40c040" intensity={0.7} groundColor="black" />
              <pointLight intensity={1} />
              <Model path={"/" + node.model} />
              {/* <Environment preset="sunset" background /> */}
            </Stage>
            </Suspense>
          </Canvas>
      </>
    );
  }
}

export default Part;
