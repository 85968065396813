import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';

function Features() {
  return (
    <div className="App-view">
      <ListGroup>
        <ListGroup.Item>
          Business features:<br /><br />
          <ul>
            <li>Public repository for velocity and marketing</li>
            <li>Private repositories for confidentiality and collaboration</li>
          </ul>
        </ListGroup.Item>
        <ListGroup.Item>
          Part features:<br /><br />
          <ul>
            <li>Codify the design intent <i>(in progress)</i></li>
            <li>Code-to-CAD support (OpenSCAD, CadQuery, build123d)</li>
            <li>CAD file formats (STEP)</li>
            <li>Mesh file formats (STL, 3MF)</li>
            <li>More formats gets added regularly</li>
            <li>No format will be left behind</li>
          </ul>
        </ListGroup.Item>
        <ListGroup.Item>
          Assembly features:<br /><br />
          <ul>
            <li>An intuitive and simple way to snap parts to each other <i>(in progress)</i></li>
            <li>Generated assembly instructions <i>(in progress)</i></li>
          </ul>
        </ListGroup.Item>
        <ListGroup.Item>
          User experience:<br /><br />
          <ul>
            <li>Visual Studio Code extension</li>
            <li>Command line interface</li>
          </ul>
        </ListGroup.Item>
        <ListGroup.Item>
          Other features:<br /><br />
          <ul>
            <li>Secure execution of third-party CadQuery and Build123d scripts in a sandboxed environment <i>(in progress)</i></li>
          </ul>
        </ListGroup.Item>
      </ListGroup>
      <center style={{ width: "75%", marginTop: 40 }}>
        See <Link to="https://partcad.readthedocs.io/">PartCAD documentation</Link> for more information about features.
      </center>
    </div>
  );
}

export default Features;
