import logo from './../assets/images/logo.png';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { useState } from 'react';

function Header() {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg" expanded={expanded}>
        <Container>
          {/* <Navbar.Brand href="/" style={{fontFamily: "Helvetica", fontWeight: 900}}> */}
          <Navbar.Brand style={{fontFamily: "Helvetica", fontWeight: 900}}>
            <Link className='navbar-brand' to="/">
              <img
                alt=""
                src={logo}
                width="30"
                height="30"
                className="d-inline-block align-top"
              />{' '}
              PartCAD
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
            </Nav>
            <Nav>
              <Nav.Link as={Link} onClick={() => setExpanded(false)} to="/repository">Repository</Nav.Link>
              <Nav.Link as={Link} onClick={() => setExpanded(false)} to="/users">Users</Nav.Link>
              <Nav.Link  as={Link} onClick={() => setExpanded(false)} to="/projects">Programs</Nav.Link>
              {/* <Nav.Link href="/features">Features</Nav.Link> */}
              {/* <Nav.Link as={Link} onClick={() => setExpanded(false)} to="/consulting">Support</Nav.Link> */}
              {/*<Nav.Link href="/consume">Consume</Nav.Link>
              <Nav.Link href="/produce">Produce</Nav.Link> */}
              {/* <Nav.Link href="https://partcad.readthedocs.io/">Documentation</Nav.Link> */}
              {/* <Nav.Link as={Link} onClick={() => setExpanded(false)} to="/contribute">Contribute</Nav.Link> */}
              <NavDropdown align="end" menuVariant='dark' expand="lg" title="Help" id="nav-dropdown">
                <NavDropdown.Item as={Link} onClick={() => setExpanded(false)} to="/consulting">Why PartCAD?</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="https://partcad.readthedocs.io/">Browse Documentation</NavDropdown.Item>
                <NavDropdown.Item as={Link} onClick={() => setExpanded(false)} to="/contribute">How to Contribute?</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
