import social_linkedin from '../assets/images/social-linkedin.png';
import social_github from '../assets/images/social-github.png';
import social_x from '../assets/images/social-x.png';
import social_youtube from '../assets/images/social-youtube.png';
import social_tiktok from '../assets/images/social-tiktok.png';
import social_facebook from '../assets/images/social-facebook.png';
import social_instagram from '../assets/images/social-instagram.png';
import social_threads from '../assets/images/social-threads.png';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

function Footer() {
  const style_container = {
    paddingTop: "40px",
    paddingBottom: "40px",
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    // width: '18rem',
    backgroundColor: "#282c34",
    minWidth: "100%",
  };
  const style_card = {
    width: '14rem',
    border: "none",
  };
  const style_body = {
    backgroundColor: "#282c34",
  };
  const style_title = {
    fontSize: 16,
    color: '#ccc',
    paddingBottom: "10px",
    fontWeight: 900,
  };
  const style_link = {
    display: 'block',
    fontSize: 16,
    color: '#ccc',
    textDecoration: "none",
    // paddingLeft: "8px",
  };
  const style_social_icon = {
    filter: "invert(100%)",
    marginBottom: "13px",
    marginRight: "13px",
    width: 24,
    height: 24,
  };

  return (
    <div style={{display: "block", marginTop: "auto"}}>
      <hr style={{border: "none", backgroundColor: "#F5BB2B", opacity: 1.0, color: "#F5BB2B", marginTop: "80px", marginBottom: 0, paddingBottom: 0, height: "8px"}}></hr>
      <Container style={style_container}>
        <Card style={style_card}>
          <Card.Body style={style_body}>
            <Card.Title style={style_title}>
              <a target="_new" href="https://www.linkedin.com/company/partcad"><img style={style_social_icon} alt="PartCAD on LinkedIn" src={social_linkedin} /></a>
              <a target="_new" href="https://github.com/openvmp/partcad"><img style={style_social_icon} alt="PartCAD on GitHub" src={social_github} /></a>
              <a target="_new" href="https://www.youtube.com/@PartCAD"><img style={style_social_icon} alt="PartCAD on YouTube" src={social_youtube} /></a>
              <a target="_new" href="https://www.facebook.com/profile.php?id=61568171037701"><img style={style_social_icon} alt="PartCAD on Facebook" src={social_facebook} /></a>
              <a target="_new" href="https://instagram.com/partcadofficial"><img style={style_social_icon} alt="PartCAD on Instagram" src={social_instagram} /></a>
              <a target="_new" href="https://x.com/PartCAD"><img style={style_social_icon} alt="PartCAD on X.com" src={social_x} /></a>
              <a target="_new" href="https://threads.net/@partcadofficial"><img style={style_social_icon} alt="PartCAD on Threads" src={social_threads} /></a>
              <a target="_new" href="https://tiktok.com/@partcad"><img style={style_social_icon} alt="PartCAD on TikTok" src={social_tiktok} /></a>
            </Card.Title>
          </Card.Body>
        </Card>

        <Card style={style_card}>
          <Card.Body style={style_body}>
            <Card.Title style={style_title}>Resources</Card.Title>
            <Card.Text>
              {/* <Link style={style_link} to="/tutorials">Tutorials</Link> */}
              <a style={style_link} href="https://partcad.readthedocs.io/">Documentation</a>
              {/* <Link style={style_link} to="/integrations">Integrations</Link> */}
              {/* <Link style={style_link} to="/partners">Partners</Link> */}
              <Link style={style_link} to="mailto:support@partcad.org">Support</Link>
              {/* <Link style={style_link} to="/support">Support</Link> */}
            </Card.Text>
          </Card.Body>
        </Card>

        <Card style={style_card}>
          <Card.Body style={style_body}>
            <Card.Title style={style_title}>Project</Card.Title>
            <Card.Text>
              {/* <Link style={style_link} to="/source_code">GitHub</Link> */}
              <a style={style_link} href="https://github.com/openvmp/partcad/">GitHub</a>
              <Link style={style_link} to="/about">About Us</Link>
              <Link style={style_link} to="/support">Contact</Link>
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default Footer;
