import { useState, useRef } from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Overlay from 'react-bootstrap/Overlay'
import { Link } from 'react-router-dom';

function Contribute() {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const target2 = useRef(null);

  return (
    <div className="App-view">
      <center style={{ width: "75%" }}>
        Do you have some spare time or resources on your hands?
        <br/>
        Don't know how to help?
        <br/>
        <Button style={{border: "none", color:"black", backgroundColor: '#F5BB2B', marginTop: 10, marginBottom: 40}} href="https://calendly.com/partcad-support/30min">Let's chat!</Button>
      </center>

        <h3 align="center">Contribute to PartCAD</h3>
        <Row lg={12} style={{width: "80%"}}>
          By improving PartCAD technologies themselves, you improve the platform
          capabilities for all of its users, no matter whether they are using public
          packages or working on hermetic private repositories.
          <br/>
          <br/>
        </Row>

        <Row lg={12} style={{width: "80%"}}>
          <Col sm={6} md={4} lg={4}>
            <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
                <Card.Title style={{ margin: 15 }}>
                  Ideas and Feedback
                </Card.Title>
                <Card.Body>
                  Share your ideas and feedback using <a href="https://discord.gg/h5qhbHtygj">the PartCAD&apos;s Discord server</a>.
                </Card.Body>
            </Card>
          </Col>
          <Col sm={6} md={4} lg={4}>
            <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
              <Card.Title style={{ margin: 15 }}>
                Financial Support
              </Card.Title>
              <Card.Body>
                For larger contributions
                (accompanied with a particular feature request or not),
                please, reach out to <a href="mailto:support@partcad.org">us</a> directly.
                We also accept contributions in the form of AI-capable
                hardware(<Button style={{padding: 0, margin: 0, display: 'inline-block', backgroundColor: '#0000', borderColor: '#0000'}} ref={target2} onClick={() => setShow(!show)}>*</Button>).
                <Overlay target={target2.current} show={show} placement="right">
                {({
                  placement: _placement,
                  arrowProps: _arrowProps,
                  show: _show,
                  popper: _popper,
                  hasDoneInitialMeasure: _hasDoneInitialMeasure,
                  ...props
                }) => (
                  <div
                    {...props}
                    style={{
                      position: 'absolute',
                      left: 0,
                      backgroundColor: 'rgba(255, 100, 100, 0.85)',
                      padding: '2px 10px',
                      color: 'white',
                      borderRadius: 3,
                      ...props.style,
                    }}
                  >
                    We are committed to never produce a single gram of CO2 by pointlessly peforming
                    cryptographic operations for the purpose of financial speculations or taking
                    advantage of cryptography challenged.
                  </div>
                )}
              </Overlay>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} md={4} lg={4}>
            <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
              <Card.Title style={{ margin: 15 }}>
              Develop Features
              </Card.Title>
              <Card.Body>
                Send a pull request to <Link to="https://github.com/openvmp/partcad/">the PartCAD&apos;s main Github repository</Link>.
                <br/><br/>
                Engage with the community on <Link to="https://discord.gg/h5qhbHtygj">PartCAD&apos;s Discord server</Link> if
                you need help deciding what could be the best project for you to work on.
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} md={12} lg={12}>
            <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
              <Card.Title style={{ margin: 15 }}>
                Documentation and Tutorials
              </Card.Title>
              <Card.Body>
                Currently, our team is short on skills required to document PartCAD well.
                Join us and spread the word!
                You can make a huge impact on lots of people by simply creating a simple post
                or a basic video tutorial.
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <br/><br/>
        <h3 align="center">Contribute to the Public PartCAD Repository</h3>
        <Row lg={12} style={{width: "80%", marginTop: 20}}>
          {/* By improving PartCAD technologies themselves, you improve the platform
          capabilities for all of its users, no matter whether they are using public
          packages or working on hermetic private repositories. */}
        </Row>

        <Row lg={12} style={{width: "80%"}}>
          <Col sm={12} md={6} lg={4}>
            <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
                <Card.Title style={{ margin: 15 }}>
                  Assemblies
                </Card.Title>
                <Card.Body>
                  Migrate your products to PartCAD by creating and publishing Assembly YAML files
                  for your products. Eliminate the need to develop and maintain a custom way to
                  store and to publish assembly instructions and bills of materials.
                  Enable the use or your assemblies in other PartCAD projects.
                  <br/><br/>
                  Start by creating a package (in a dedicated git repository)
                  for all products by your company or community.
                  Then add parametrized assembly declarations to the package.
                  <br/><br/>
                  The best way to write Assembly YAML files is to use PartCAD VS Code extension
                  and use code completion features: select the part you want to add in the
                  explorer view on the left and, then,
                  start typing '- part: ' in the Assembly YAML file editor as if you were to add
                  a new part to the assembly. After you typed "- pa", a code completion suggestion
                  will show up, offering to add the complete YAML block which adds the selected
                  part to the assembly.
                </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={6} lg={4}>
            <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
                <Card.Title style={{ margin: 15 }}>
                  Parts
                </Card.Title>
                <Card.Body>
                  Publish your parts in the PartCAD Public Repository to enable their use (and
                  purchase if applicable) by other PartCAD users.
                  <br/><br/>
                  Start by creating a package (in a dedicated git repository)
                  for all parts and assemblies created by your company
                  or community, if you didn't do that yet.
                  Then add part declarations to the package.
                  <br/><br/>
                  If CAD files already exist for these parts, then you should probably start by
                  using those CAD files first. You can always migrate to Code-CAD technologies
                  (such as OpenSCAD, CadQuery or build123d) later.
                  (<Button style={{padding: 0, margin: 0, display: 'inline-block', backgroundColor: '#0000', borderColor: '#0000'}} ref={target} onClick={() => setShow(!show)}>*</Button>).
                  <Overlay target={target.current} show={show} placement="right">
                    {({
                      placement: _placement,
                      arrowProps: _arrowProps,
                      show: _show,
                      popper: _popper,
                      hasDoneInitialMeasure: _hasDoneInitialMeasure,
                      ...props
                    }) => (
                      <div
                        {...props}
                        style={{
                          position: 'absolute',
                          left: 0,
                          backgroundColor: 'rgba(255, 100, 100, 0.85)',
                          padding: '2px 10px',
                          color: 'white',
                          borderRadius: 3,
                          ...props.style,
                        }}
                      >
                        However you might want to host
                        the CAD files separately from the package's git repository
                        (and have PartCAD fetch them using `fileFrom: url`,
                        even if they are hosted in another git repository)
                        so that you do not blow up the repository size and
                        slow your projects down in perpetuity by a temporary use of legacy CAD files.
                      </div>
                    )}
                  </Overlay>
                  <br/><br/>
                  After that declare the location of ports.
                  This will enable connecting parts to each other, instead of placing them using
                  absolute and relative coordinates and ensuring they remain properly co-located
                  using finger-crossing guarantees.
                  Whenever possible, use interfaces and mating declarations insteaf of pure ports,
                  so that it's easier for PartCAD (including AIs using PartCAD) to determine
                  which parts are meant to be connected to which parts, and how exactly they need
                  to be connected.
                </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={6} lg={4}>
            <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
                <Card.Title style={{ margin: 15 }}>
                  Interfaces
                </Card.Title>
                <Card.Body>
                  The most challenging step of adding parts is to declare all the ports.
                  This effort can be significantly reduced for all PartCAD users globally
                  by extending the library of standard interfaces.
                  <br/><br/>
                  Does your part have a port of the kind also found in many other parts?
                  Consider declaring a reusable interface.
                </Card.Body>
            </Card>
          {/* </Col>
          <Col sm={12} md={6} lg={4}> */}
            <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
                <Card.Title style={{ margin: 15 }}>
                  Providers
                </Card.Title>
                <Card.Body>
                  Advertise your online store and manufacturing services by integrating your API
                  with PartCAD.
                  <br/><br/>
                  Decide which one is more appropriate for your business:
                  <b>store</b> or <b>manufacturer</b>. Then see an existing provider implementation of
                  that kind as a reference. Alternatively, reach out to the PartCAD team to get
                  help with the implementation. Be at the forefront of the next industrial
                  revolution together with PartCAD!
                </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={6} lg={12}>
            <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
                <Card.Title style={{ margin: 15 }}>
                  Help a Friend
                </Card.Title>
                <Card.Body>
                  Do you know an opensource project that maintains assembly instructions or doing
                  something else that can be radically improved by using PartCAD?
                  <br/><br/>
                  Do you know a business that uses legacy tools and struggles to scale
                  collaboration in the era of Git?
                  <br/><br/>
                  Do you know a local additive manufacturing shop that can use more local
                  customers?
                  <br/><br/>
                  Do you know a collection of parts that the community can really benefit from if
                  only these parts and corresponding assembly ideas were easily discoverable?
                  <br/><br/>
                  Help them migrate to PartCAD! 
                </Card.Body>
            </Card>
          </Col>
        </Row>
    </div>
  );
}

export default Contribute;
