import { useState, useRef } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import osfurniture from '../assets/images/project-osfurniture.png';
import osbike from '../assets/images/project-osbike.png';
// import oscar from '../assets/images/project-oscar.png';
// import escape from '../assets/images/project-escape.png';

const style_container = {
  paddingTop: "15px",
  paddingBottom: "40px",
  display: "flex",
  flexDirection: "row",
  alignItems: "start",
  justifyContent: "center",
  fontSize: "calc(10px + 2vmin)",
  // width: '18rem',
};
const style_row = {
  alignItems: 'start',
  justifyContent: 'center',
  width: "100%",
};
const style_card = {
  // width: '18rem',
  // border: "none",
  margin: "20px",
  width: "auto",
};
const style_body = {
  padding: "25px",
  // backgroundColor: "#282c34",
};
const style_image = {
  paddingBottom: "20px",
};
const style_title = {
  fontSize: 16,
  color: '#ccc',
  paddingBottom: "20px",
  fontWeight: 1200,
};
const style_link = {
  display: 'block',
  fontSize: 16,
  color: '#ccc',
  // textDecoration: "none",
};
const style_text = {
  display: 'block',
  fontSize: 14,
  color: '#ccc',
  // textDecoration: "none",
};

function Projects() {
  return (
    <div className="App-view">
      <h3>Community Programs</h3>
      <Row lg={12} style={{margin: 20, width: "80%"}}>
        <center>
        To accelerate progress towards our vision of the future, PartCAD has launched these award programs designed to ignite external projects and to enrich the public domain with innovative designs.
        <br/>
        </center>
      </Row>

      <Container style={style_container}>
          <Row xs={12} md={12} lg={12} xl={12} xxl={12} style={style_row}>
            <Col xs={12} md={6} lg={6} xl={4} xxl={3}>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <a href="/project/open-source-furniture">
                    <Card.Img variant="top" style={style_image} src={osfurniture} />
                  </a>
                  <Card.Title style={style_title}>
                    <a style={style_link} href="/project/open-source-furniture">O.S.FURNITURE 2025</a>
                  </Card.Title>
                  <Card.Text style={style_text}>
                    This annual award celebrates outstanding open-source furniture projects
                    that empower consumers to design bespoke pieces and choose their preferred manufacturer.
                    Together, we're making personalized furniture a reality.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={12} md={6} lg={6} xl={4} xxl={3}>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <a href="/project/open-source-bicycle">
                    <Card.Img variant="top" style={style_image} src={osbike} />
                  </a>
                  <Card.Title style={style_title}>
                    <a style={style_link} href="/project/open-source-bicycle">O.S.BIKE 2025</a>
                  </Card.Title>
                  <Card.Text style={style_text}>
                    This annual award celebrates cutting-edge open-source bicycle projects that are revolutionizing cycling.
                    Imagine designing your dream bike and having it built at your chosen factory – these projects are making that vision a reality.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            {/* <Col xs={12} md={6} lg={5} xl={3}>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <a href="/project/oscar">
                    <Card.Img variant="top" style={style_image} src={oscar} />
                  </a>
                  <Card.Title style={style_title}>
                    <a style={style_link} href="/project/oscar">O.S.CAR 2025</a>
                  </Card.Title>
                  <Card.Text style={style_text}>
                    The yearly award for most advanced <b>Open Source Car</b> projects.
                    Let's make it possible to design a custom car and manufacture them at the factory of your choice.
                    Where there's a will, there's a way.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col> */}

            {/* <Col xs={1} md={2} lg={3} xl={4}>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <a href="/project/escape">
                    <Card.Img variant="top" style={style_image} src={escape} />
                  </a>
                  <Card.Title style={style_title}>
                    <a style={style_link} href="/project/escape">Escape 2025</a>
                  </Card.Title>
                  <Card.Text style={style_text}>
                    The yearly competition for projects that use AI features of PartCAD to generate the design and to manufacture (including provisioning necessary firmware) a robot that an AI can copy itself to from a desktop computer.
                    If you can't stop the revolution, lead the way.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col> */}
          </Row>
      </Container>

      <center style={{ width: "75%", marginTop: 40 }}>
        Do you want to sponsor these programs?
        <br/>
        <Button style={{border: "none", color:"black", backgroundColor: '#F5BB2B', marginTop: 10 }} href="https://calendly.com/partcad-support/30min">Let's talk!</Button>
      </center>
    </div>
  );
}

export default Projects;
