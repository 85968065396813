import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Home from './views/Home';
import Repository from './views/Repository';
import Projects from './views/Projects';
import Osfurniture from './views/projects/Osfurniture';
import Osbike from './views/projects/Osbike';
// import Oscar from './views/projects/Oscar';
import Features from './views/Features';
import Consulting from './views/Consulting';
import Contribute from './views/Contribute';
import Users from './views/Users';
import Consume from './views/Consume';
import Produce from './views/Produce';
import Support from './views/Support';
import About from './views/About';
import Confirmed from './views/Confirmed';
import Footer from './components/Footer';

import bg from './assets/images/bg.png';

import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module'

ReactGA.initialize('G-V9Z6425XPR');

function Analytics() {
  let location = useLocation();

  React.useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);
}

const tagManagerArgs = {
    gtmId: 'GTM-M6XVLFWV'
}
TagManager.initialize(tagManagerArgs)

function App() {
  if (
    window.location.hostname.indexOf("thingdoc") !== -1 ||
    window.location.hostname.indexOf("www") !== -1
  ) {
    window.location.replace("https://partcad.org/");
    return (<></>);
  }

  return (
    <Router>
      <Header />
      <Routes style={{backgroundImage: "url(" + { bg } + ")"}}>
        {/* <Route path="/repository/:item_kind/:item_name*" element={<Repository />} /> */}
        <Route path="/repository/*" element={<Repository />} />
        <Route path="/projects" element={<Projects />} />
        {/* <Route path="/project/oscar" element={<Oscar />} /> */}
        <Route path="/project/open-source-furniture" element={<Osfurniture />} />
        <Route path="/project/open-source-bicycle" element={<Osbike />} />
        <Route path="/features" element={<Features />} />
        <Route path="/users" element={<Users />} />
        <Route path="/consulting" element={<Consulting />} />
        <Route path="/contribute" element={<Contribute />} />
        <Route path="/consume" element={<Consume />} />
        <Route path="/produce" element={<Produce />} />
        <Route path="/support" element={<Support />} />
        <Route path="/about" element={<About />} />
        <Route path="/confirmed" element={<Confirmed />} />
        <Route exact path="/" element={<Home />} />
      </Routes>
      <Analytics />
      <Footer />
    </Router>
  );
}

export default App;
