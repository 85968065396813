import { useState, useRef } from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Overlay from 'react-bootstrap/Overlay'
import { Link } from 'react-router-dom';

function Consulting() {
  return (
    <div className="App-view">
      <Row lg={12} style={{width: "80%"}}>
        <center align="center">
        How does PartCAD fit into YOUR workflows and infrastructure?
        <br/>
        <Button style={{border: "none", color:"black", backgroundColor: '#F5BB2B', marginTop: 10, marginBottom: 40}} href="https://calendly.com/partcad-support/30min">Learn more!</Button>
        </center>
      </Row>

      <h3 align="center">Assembly Manufacturing</h3>
      <Row lg={12} style={{width: "80%"}}>
        <Col sm={6} md={6} lg={6} xl={4}>
          <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
              {/* <Card.Title>
                2024 Batch
              </Card.Title> */}
              <Card.Body>
                If you have a <b>big team</b> designing mechanical products,
                we are happy to help you to migrate to PartCAD for:
                <br/><br/>
                <ul>
                <li>Change management at the speed of Git</li>
                <li>Improved collaboration and scaling</li>
                <li>Automating quality controls</li>
                </ul>
              </Card.Body>
          </Card>
        </Col>
        <Col sm={6} md={6} lg={6} xl={4}>
          <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
              {/* <Card.Title>
                2024 Batch
              </Card.Title> */}
              <Card.Body>
                If you <b>assemble complex products</b>,
                we are happy to help you to migrate to PartCAD for:
                <br/><br/>
                <ul>
                <li>Generating assembly and part ordering instructions</li>
                <li>Automating the assembly process</li>
                <li>Automating product validation</li>
                </ul>
              </Card.Body>
          </Card>
        </Col>
        <Col sm={6} md={6} lg={6} xl={4}>
          <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
              {/* <Card.Title>
                2024 Batch
              </Card.Title> */}
              <Card.Body>
                If you design <b>complex mechanical products</b>,
                we are happy to help you to migrate to PartCAD for:
                <br/><br/>
                <ul>
                <li>Reducing CAD and PLM costs</li>
                <li>Automated rendering of blueprints</li>
                <li>Generating assembly and part ordering instructions</li>
                </ul>
              </Card.Body>
          </Card>
        </Col>
        <Col sm={6} md={6} lg={6} xl={4}>
          <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
              {/* <Card.Title>
                2025 Batch
              </Card.Title> */}
              <Card.Body>
                If you publish <b>do-it-yourself</b> instructions,
                we are happy to help you to migrate to PartCAD for:
                <br/><br/>
                <ul>
                <li>Reducing maintenance costs</li>
                <li>Using an opensource implementation</li>
                <li>Automated release process</li>
                </ul>
              </Card.Body>
          </Card>
        </Col>
        <Col sm={6} md={6} lg={6} xl={4}>
          <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
              {/* <Card.Title>
                2025 Batch
              </Card.Title> */}
              <Card.Body>
                If you want your team to <b>start using AI</b>,
                we are happy to help you to migrate to PartCAD for:
                <br/><br/>
                <ul>
                <li>Ensuring confidentiality while using AI</li>
                <li>Improved effectiveness and efficiency</li>
                <li>Sharing expertise in AI for mechanical design</li>
                </ul>
              </Card.Body>
          </Card>
        </Col>
      </Row>

      <h3>Part Manufacturing</h3>
      <Row lg={12} style={{width: "80%"}}>
        <Col sm={6} md={6} lg={6} xl={4}>
          <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
              {/* <Card.Title>
                2024 Batch
              </Card.Title> */}
              <Card.Body>
                If you <b>sell custom parts</b>,
                then we can help you market the parts in the PartCAD public repository
                so that they can be discovered and automatically purchased by more users.
                <br/><br/>
                <ul>
                <li>Publish part blueprints</li>
                <li>Ensure discoverability</li>
                <li>Comprehensible by AI</li>
                </ul>
              </Card.Body>
          </Card>
        </Col>
        <Col sm={6} md={6} lg={6} xl={4}>
          <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
              {/* <Card.Title>
                2024 Batch
              </Card.Title> */}
              <Card.Body>
                If you <b>manufacture parts</b> on demand
                (including but not limited to <b>additive manufacturing</b>),
                then we can help you to integrate with PartCAD:
                <br/><br/>
                <ul>
                <li>Integrate your APIs</li>
                <li>Market your materials</li>
                <li>Community relationships</li>
                </ul>
              </Card.Body>
          </Card>
        </Col>
        <Col sm={6} md={6} lg={6} xl={4}>
          <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
              {/* <Card.Title>
                2024 Batch
              </Card.Title> */}
              <Card.Body>
                If you are considering to use AI, we can help
                <br/><br/>
                <ul>
                <li>Use AI with confidentiality</li>
                <li>Use AI effectively and efficiently</li>
                <li>Design new parts or improvements</li>
                </ul>
              </Card.Body>
          </Card>
        </Col>

      </Row>
    </div>
  );
}

export default Consulting;
