function Produce() {
  return (
    <div className="App-view">
      <h1>How to add content to PartCAD?</h1>
      <center style={{ width: "75%", marginTop: 40 }}>
        Whether to publish parts, assemblies,
        or APIs for others to purchase or to manufacture parts and assemblies,
        a PartCAD package needs to be developed.
      </center>
      <center style={{ width: "75%", marginTop: 40 }}>
        The recommended way to compose and to test a PartCAD package is the PartCAD VSCode extension.
      </center>
      <center style={{ width: "75%", marginTop: 40 }}>
        For parts, the preferred way to describe parts is by using OpenSCAD, CadQuery or build123d (our favorite).
        However a CAD model file (STEP, STL, 3MF) can also be used.
        Annotate the part with design intent information such as possible weight and size values,
        location of attachment or mating points and, if applicable, how it is expected to be manufactured (e.g. 3D printing parameters). 
      </center>
      <center style={{ width: "75%", marginTop: 40 }}>
        For assemblies, at the moment, the only supported format is the PartCAD's own "Assembly YAML" ("*.assy").
        It allows to easily enumerate which parts are required and how to connect them to each other.
        It supports Jinja2 templates for scripting purposes.
      </center>
    </div>
  );
}

export default Produce;
