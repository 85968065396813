import bg_collaboration from '../assets/images/bg-collaboration.jpg';
import bg_design from '../assets/images/bg-design.jpg';
import bg_part from '../assets/images/bg-part.jpg';
import bg_api from '../assets/images/bg-api.jpg';
import bg_repo from '../assets/images/bg-repo.jpg';
import bg_ops from '../assets/images/bg-ops.jpg';
import bg_open_source from '../assets/images/bg-open-source.jpg';
import bg_free from '../assets/images/bg-free.jpg';
import bg_vscode from '../assets/images/bg-vscode.jpg';
import bg_cli from '../assets/images/bg-cli.jpg';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import { Col, Row, Button } from 'react-bootstrap';

function Home() {
  const car_img_style = {
    // "maxHeight": "400px",
    // "minHeight": "400px",
    height: "25rem",
    objectFit: "cover",
  };

  const style_caption = {
      textShadow: "-4px -4px 8px #000, 4px 4px 8px #000, 4px -4px 8px #000, -4px 4px 8px #000",
      textColor: "#fff",
      color: "#fff",
      };

  const style_container = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    // width: '18rem',
    paddingTop: 20,
  };
  const style_card = {
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 5,
    marginRight: 5,
    // width: '18rem',
    // border: "none",
    height: "calc(100% - 10px)",
    display: "flex" ,
    flexDirection: "column",
  };
  const style_body = {
    padding: 10,
    // padding: "25px",
    // backgroundColor: "#282c34",
    textAlign: "center",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };
  const style_image = {
    paddingBottom: 10,
    // paddingBottom: "20px",
    // flex: "auto",
  };
  const style_title = {
    fontSize: 16,
    color: '#ccc',
    paddingBottom: 10,
    fontWeight: 1200,
    flex: "none",
  };
  const style_link = {
    paddingTop: 10,
    // display: 'block',
    fontSize: 16,
    color: '#ccc',
    // textDecoration: "none",
    fontWeight: 200,
    // textDecoration: "none",
    flex: "none",
    fontStyle: "italic",
  };

  return (
    <div className="App-view">
      <center style={{ width: "80%" }}>
        <Link to="https://github.com/openvmp/partcad">PartCAD</Link>
        &nbsp;organizes the world{"'"}s design information and makes it accessible for all.
        <br/>
        It{"'"}s the first package manager for CAD models,
        and a framework for designing and manufacturing assemblies.
        <br/><br/>
        It brings together all recent innovations in mechanical engineering,
        amplifies them by the best practices of software engineering,
        and breaks away by native support for AI.
      </center>

      <center style={{ width: "80%", marginTop: 20 }}>
        See <Link to="https://partcad.readthedocs.io/">documentation</Link> to learn how to use PartCAD.
      </center>

      <center style={{ width: "80%", marginTop: 40 }}>
        <Button style={{border: "none", color:"black", backgroundColor: '#F5BB2B'}} href="https://calendly.com/partcad-support/30min">Let's chat!</Button>
      </center>
 
 <Container>
      <Carousel style={{ marginTop: "40px" }}>
        <Carousel.Item bg="dark">
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg_collaboration}
            alt="State-Of-The-Art Collaboration"
          />
          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>State-Of-The-Art Collaboration</h3>
            <p>
              Collaborate on your designs using the industry standard tool: Git
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item bg="dark">
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg_design}
            alt="Publish Designs"
          />
          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Publish Designs</h3>
            <p>
              Provide your users with assembly instructions, bill of materials,
              and an easy way to purchase or to manufacture all the parts.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg_part}
            alt="Advertise Parts"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Advertise Parts</h3>
            <p>
              Publish your parts so that others can use these parts in their assemblies.
              Provide your users with ordering and manufacturing instructions.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg_api}
            alt="Reach More Customers"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Reach More Customers</h3>
            <p>
              Enable the use of your API for additive and subtractive manufacturing
              of corresponding parts in some assemblies.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg_repo}
            alt="Simplify Development"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Simplify Development</h3>
            <p>
              Optionally, use a private repository to enable your developers
              to collaborate on assembly instructions effectively.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg_ops}
            alt="Simplify Operations"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Simplify Operations</h3>
            <p>
              Share your product&apos;s assembly instructions
              with contract manufacturing providers to reduce the overhead.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg_open_source}
            alt="Open Platform"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Open Platform</h3>
            <p>
              Open source (Apache 2.0) platform for the top innovation speed.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg_free}
            alt="Free Tools"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Free Tools</h3>
            <p>
              No subsbcription or per-seat costs. No one time payments. None.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg_vscode}
            alt="VS Code Extension"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>VS Code Extension</h3>
            <p>
              Use our Visual Studio Code extension to develop your blueprints.<br />
              Enjoy the user experience that powers most of development workflows worldwide.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg_cli}
            alt="Command Line Tools"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Command Line Tools</h3>
            <p>
              Use our CLI tools for automation or hardcore development experiences.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      </Container>
      {/* <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Open Versatile Mobility Platform (OpenVMP) implements multi-modal mobility mechanisms that perform arbitrary tasks individually or collectively.
          </p>
          <a
            className="App-link"
            href="https://gitbub.com/openvmp/openvmp"
            target="_blank"
            rel="noopener noreferrer"
          >
            Repository
          </a>
        </header>
      </div> */}

      <div className="featured-view">
        Featured assemblies:
        <Row style={{maxWidth: "100%", justifyContent: "center"}}>
        <Col style={{}} xs={12} sm={6} md={4} lg={4} xl={3} xxl={2}>
          <Link className="TreeItem" to={`/repository/assembly/robotics/multimodal/openvmp/robots/don1:robot`}>
          <Card bg="dark" style={style_card}>
            <Card.Body style={style_body}>
              <Card.Title style={style_title}>Don1 is the first OpenVMP "proof-of-concept" robot</Card.Title>
              <div style={{flex: 1, display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center", alignItems: "center"}}>
                <Card.Img variant="top" style={style_image} src="/content/robotics/multimodal/openvmp/robots/don1/robot.svg" />
              </div>
            </Card.Body>
          </Card>
          </Link>
        </Col>
        <Col style={{}} xs={12} sm={6} md={4} lg={4} xl={3} xxl={2}>
          <Link className="TreeItem" to={`/repository/assembly/furniture/workspace/basic:imperial-desk-1`}>
          <Card bg="dark" style={style_card}>
            <Card.Body style={style_body}>
              <Card.Title style={style_title}>Basic desk using standard imperial dimensional lumber</Card.Title>
              <div style={{flex: 1, display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center", alignItems: "center"}}>
                <Card.Img variant="top" style={style_image} src="/content/furniture/workspace/basic/imperial-desk-1.svg" />
              </div>
            </Card.Body>
          </Card>
          </Link>
        </Col>
        <Col style={{}} xs={12} sm={6} md={4} lg={4} xl={3} xxl={2}>
          <Link className="TreeItem" to={`/repository/assembly/robotics/multimodal/openvmp/robots/don1:assembly-wormgear`}>
          <Card bg="dark" style={style_card}>
            <Card.Body style={style_body}>
              <Card.Title style={style_title}>Worm gearbox assembly using goBILDA parts</Card.Title>
              <div style={{flex: 1, display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center", alignItems: "center"}}>
                <Card.Img variant="top" style={style_image} src="/content/robotics/multimodal/openvmp/robots/don1/assembly-wormgear.svg" />
              </div>
            </Card.Body>
          </Card>
          </Link>
        </Col>
        </Row>
      </div>
    </div>
  );
}

export default Home;
