import { useState, useRef } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import osfurniture from '../../assets/images/project-osfurniture.png';

const style_container = {
  paddingTop: "15px",
  paddingBottom: "40px",
  display: "flex",
  flexDirection: "row",
  alignItems: "start",
  justifyContent: "center",
  fontSize: "calc(10px + 2vmin)",
  // width: '18rem',
};
const style_row = {
  alignItems: 'start',
  justifyContent: 'center',
  width: "100%",
};
const style_card = {
  // width: '18rem',
  // border: "none",
  margin: "20px",
  width: "auto",
};
const style_body = {
  padding: "25px",
  // backgroundColor: "#282c34",
};
const style_image = {
  paddingBottom: "20px",
};
const style_title = {
  fontSize: 16,
  color: '#ccc',
  paddingBottom: "20px",
  fontWeight: 1200,
};
const style_link = {
  display: 'block',
  fontSize: 16,
  color: '#ccc',
  // textDecoration: "none",
};
const style_text = {
  display: 'block',
  fontSize: 14,
  color: '#ccc',
  // textDecoration: "none",
};

function Osfurniture() {
  return (
    <div className="App-view">
      <h1 align="center"><span style={{}}>O.S.FURNITURE</span><br/><span style={{color: '#F5BB2B'}}>The Open Source Furniture yearly award</span></h1>
      {/* <Row lg={12} style={{margin: 20, width: "80%"}}>
        <center>
        The below projects are initiated to advance the adoption of PartCAD as well as to achieve the long term vision of the future that motivated the creation of PartCAD.
        <br/>
        </center>
      </Row> */}

      <Container style={style_container}>
        <Row xs={12} md={11} lg={10} xl={9} style={style_row}>
          <Col xs={12} md={4} lg={3} xl={3}>
            <p>
              <Card.Img variant="top" style={style_image} src={osfurniture} />
            </p>
            Current  prize pool:<br/>
            $1000<br/>
            <Button style={{border: "none", color:"black", backgroundColor: '#F5BB2B', marginTop: 10, marginBottom: 40}} href="https://calendly.com/partcad-support/30min">Pledge!</Button>
          </Col>
          <Col xs={12} md={8} lg={7} xl={6}>
            <p>
              <span style={{textDecoration: "underline"}}>END GOAL</span>
              <br/>
              <font size="5">
              All products must be:
              <ul>
                <li>very aesthetic or very functional, or both</li>
                <li>highly customizable and parameteric</li>
                <li>pass validation for assemblability</li>
              </ul>
              All parts must be:
              <ul>
                <li>pass validation for manufacturability</li>
                <li>parameteric (exceptions are OK)</li>
              </ul>
              <p>
              The whole assembly as well as each part must be available for purchase online (for all acceptable values of customizable parameters) from more than one manufacturer.
              </p>
              </font>
            </p>
          </Col>
          <Col xs={12} md={11} lg={10} xl={9}>
            <hr style={{border: "none", backgroundColor: "#F5BB2B", opacity: 1.0, color: "#F5BB2B", marginTop: 40, marginBottom: 20, paddingBottom: 0, height: "8px"}}></hr>
            <p style={{fontSize: 16}}>
              This is a forward looking project. It aims to reward those who made the most progress towards the goal in the public domain.
              There is not expectation of meeting the end goal to be considered for the award.
              It is expected that it will take years to get to fully functional designs and to have the manufacturing process ironed out.
              <br/>
              <br/>
              Designs for all spaces - living spaces, work spaces, commercial environments, and beyond - are welcome.
              <br/>
              <br/>
              Submit your project to be considered for the award at <a href="mailto:support@partcad.org">support@partcad.org</a> between August 1st and November 1st, 2025.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Osfurniture;
