function Consume() {
  return (
    <div className="App-view">
      <h1>How to use content from PartCAD?</h1>
      <center style={{ width: "75%", marginTop: 40 }}>
        For file access, use PartCAD command line tools to export (render) published parts and assemblies to te desired format. The following formats are supported: SVG, PNG, STEP, STL, 3MF, ThreeJS, OBJ. Expect more formats to be supported in the future.
      </center>

      <center style={{ width: "75%", marginTop: 40 }}>
        For Python, use "import partcad as pc" to retrieve the desired model in OpenCASCADE (OCCT), CadQuery or Build123d representation. Alternatively, use Python object API to export (render) the obtained part to the file (and format) of your choice.
      </center>

      <center style={{ width: "75%", marginTop: 40 }}>
        For web, PartCAD has not yet released the Javascript API to visualize PartCAD models within any website.
      </center>
    </div>
  );
}

export default Consume;
