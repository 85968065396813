import { Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Confirmed() {
  return (
    <div className="App-view">
      <h3>Thank you for your interest in PartCAD!</h3>
      <Row lg={12} style={{margin: 20, width: "80%"}}>
        <center>
        We are getting reading for the meeting!
        <br/>
        <br/>
        In the meantime, please, consider exploring the following information about PartCAD:
        <br/>
        <Button style={{border: "none", color:"black", backgroundColor: '#F5BB2B', marginTop: 10, marginBottom: 10}} href="https://partcad.readthedocs.io/">Documentation</Button>
        <br/>
        <Button style={{border: "none", color:"black", backgroundColor: '#F5BB2B', marginTop: 10, marginBottom: 10}} href="https://marketplace.visualstudio.com/items?itemName=OpenVMP.partcad">Visual Studio Code Extension</Button>
        <br/>
        <Button style={{border: "none", color:"black", backgroundColor: '#F5BB2B', marginTop: 10, marginBottom: 10}} href="https://github.com/openvmp/partcad/">Source Code</Button>
        </center>
      </Row>
    </div>
  );
}

export default Confirmed;
