import { useState, useRef } from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Overlay from 'react-bootstrap/Overlay'
import { Link } from 'react-router-dom';

import user_smartends from '../assets/images/users/smartends.jpg'

function Users() {
  return (
    <div className="App-view">
      <h3>PartCAD Adoption Programs</h3>
      <Row lg={12} style={{margin: 20, width: "80%"}}>
        <center>
        We are onboarding a limited number of clients to pioneer
        the next generation of mechanical design and manufacturing technologies.
        <br/>
        <br/>
        Do you think differently?
        <br/>
        Do you want to lead the way?
        <br/>
        Are you ready for the future?
        <br/>
        <br/>
        </center>
      </Row>

      <center style={{ width: "75%" }}>
        <Button style={{border: "none", color:"black", backgroundColor: '#F5BB2B', marginBottom: 40}} href="https://calendly.com/partcad-support/30min">Let's chat!</Button>
      </center>

      <Row lg={12} style={{width: "80%"}}>
        <Col sm={6} md={6} lg={6} style={{ display: "flex", flexDirection: "column", flexGrow: 1,}}>
          <Card style={{ alignItems: "start", flexGrow: 1, backgroundColor: '#333', color: '#ccc', marginBottom: 20 }}>
              <Card.Title style={{ margin: 15 }}>
                2024 Cohort
              </Card.Title>
              <Card.Body style={{ alignContent: "start", flexGrow: 1}}>
                A free program for a limited number of pilot projects.
                No hard expectations on engagement from your team:
                we do the job.
                <br/>
              </Card.Body>
              <Link to="https://smartends.com/">
                <Card.Img src={user_smartends} style={{ margin: 15, width: "25%", height: "auto" }} />
              </Link>
          </Card>
        </Col>
        <Col sm={6} md={6} lg={6}>
          <Card style={{ flexGrow: 1, backgroundColor: '#333', color: '#ccc', marginBottom: 20 }}>
              <Card.Title style={{ margin: 15 }}>
                2025 H1 Cohort
              </Card.Title>
              <Card.Body style={{ alignContent: "start", flexGrow: 1}}>
                Consulting engagements to help your team learning PartCAD and related technologies.
                Some of the migration work is done by us.
                Exact terms are discussed on per-project basis.
              </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Users;
