function Support() {
  return (
    <div className="App-view">
      <p align="center">
        Please, feel free to reach out to <a href="mailto:support@partcad.org">support@partcad.org</a> with any questions.
        <br/><br/>
        You are also welcome to <a href="https://calendly.com/partcad-support/30min">schedule a call</a> with us.
      </p>
    </div>
  );
}

export default Support;
